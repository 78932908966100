<template>
  <div class="d-flex product">
    <Carousel :list="activePic" class="product__carousel" />
    <ProductList :list="data.product" :list_en="data_en.product" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Carousel from '@/components/product/Carousel.vue'
import ProductList from '@/components/product/ProductList.vue'

export default {
  name: 'Product',
  components: {
    Carousel,
    ProductList
  },
  props: {
    id: {
      type: [String, Number],
      default: null
    },
    productId: {
      type: [String, Number],
      default: null
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('product', ['data', 'data_en']),
    activePic () {
      return this.lang === 'hk' ? this.data.pics : this.data_en.pics
    }
  },
  created () {
    this.fetchProduct(this.productId)
  },
  mounted () {
    if (this.$route.name === 'HighlightProduct') {
      this.fetchBigScreenPhase({ current: 'highlight', current_id: this.productId, lang: this.lang })
    } else {
      this.fetchBigScreenPhase({ current: 'showroom', current_id: this.id, current_child_id: this.productId, lang: this.lang })
    }
  },
  methods: {
    ...mapActions('api', ['fetchProduct', 'fetchBigScreenPhase'])
  }
}
</script>

<style lang="scss" scoped>
.product {
  &__carousel {
    width: 54.1667vw;
  }
}
</style>
