<template>
  <splide v-if="list.length" :options="options" :slides="list">
    <splide-slide v-for="item in list" :key="item">
      <img :data-splide-lazy="item" class="carousel-img">
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'Carousel',
  components: {
    Splide,
    SplideSlide
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      options: {
        perPage: 1,
        type: 'loop',
        autoplay: true,
        interval: 4000,
        speed: 1500,
        width: '54.1667vw',
        height: '71.1111vh',
        pauseOnHover: false,
        pauseOnFocus: false,
        lazyLoad: true,
        cover: true,
        classes: {
          arrow: 'splide__arrow carousel-arrow',
          prev: 'splide__arrow--prev carousel-arrow--prev',
          next: 'splide__arrow--next carousel-arrow--next',
          pagination: 'splide__pagination carousel-pagination'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.carousel-arrow {
  width: 3.3333vw;
  height: 3.3333vw;
  opacity: 1;
  top: calc(50% - 3.3333vw / 2);
  @include bg-cover;

  svg {
    display: none;
  }

  &--next {
    right: 1.5625vw;
    background-image: url('~@/assets/img/product/arrow-right-white.svg');
  }

  &--prev {
    left: 1.5625vw;
    background-image: url('~@/assets/img/product/arrow-left-white.svg');
  }
}

.carousel-pagination {
  bottom: 2.6667vh;
}

.splide__pagination__page {
  width: 1vw;
  height: 1vw;
  opacity: 0.5;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.8);
  background-color: rgba($color: $white, $alpha: 0.8);
  margin: 0 calc(0.651vw / 2);
  &.is-active {
    transform: scale(1);
    background-color: $white;
    opacity: 1;
  }
}

.carousel-img {
  width: 100%;
}
</style>
