<template>
  <div class="position-relative product-list">
    <vue-scroll ref="vs" :ops="ops" @handle-scroll="handleScroll" @handle-resize="handleResize">
      <div class="product-list__container">
        <div
          v-for="item in activeList"
          :key="item.id"
          class="d-flex product-list__item"
        >
          <div class="product-list__item-img" :style="{backgroundImage: `url(${item.pic})`}">
            <img v-if="item.new_lower_price === 'Y' && lang === 'hk'" src="@/assets/img/product/lowerprice-sticker.svg">
            <img v-if="item.new_lower_price === 'Y' && lang !== 'hk'" src="@/assets/img/product/lower-price-sticker-en.svg">
            <img v-if="item.new_product === 'Y' && lang === 'hk'" src="@/assets/img/product/newproduct-sticker.svg">
            <img v-if="item.new_product === 'Y' && lang !== 'hk'" src="@/assets/img/product/newproduct-sticker-en.svg">
          </div>
          <div class="flex-column product-list__item-info-box">
            <p class="font-bold product-list__item-brand">{{ item.brand }}</p>
            <p class="product-list__item-name">{{ item.name }}</p>
            <p class="font-bold product-list__item-price">${{ item.price }}</p>
            <p class="product-list__item-tip">{{ item.note }}</p>
          </div>
          <div class="flex-column product-list__item-interactive">
            <img class="product-list__item-qrcode" :src="item.qrcode">
            <p class="product-list__item-more-text" :class="{'product-list__item-more-text--en': lang !== 'hk'}">{{ moreText }}</p>
            <div
              :ref="`item${item.id}`"
              class="product-list__item-add"
              @click="onHeartClick(item.id)"
            >
              <img v-if="lang === 'hk'" src="@/assets/img/product/add-to-cart.svg" alt="加入購物車">
              <img v-else src="@/assets/img/product/add-to-cart-en.svg" alt="add to cart">
            </div>
          </div>
        </div>
      </div>
    </vue-scroll>
    <div v-if="tipNeedShow" class="d-flex justify-center items-center product-list__scroll-tip-block">
      <img src="@/assets/img/product/scroll.svg">
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import vuescroll from 'vuescroll'
export default {
  components: {
    'vue-scroll': vuescroll
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    list_en: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      tipNeedShow: false,
      ops: {
        rail: {
          background: '#9b9b9b',
          opacity: 0.56,
          size: '7px',
          specifyBorderRadius: '10px'
        },
        bar: {
          onlyShowBarOnScroll: false,
          keepShow: true,
          background: '#707070',
          specifyBorderRadius: true,
          size: '7px'
        }
      }
    }
  },
  computed: {
    ...mapState('lang', ['lang']),
    ...mapState('cart', ['cartList']),
    moreText () {
      return this.lang === 'hk' ? '了解更多' : 'Explore more'
    },
    activeList () {
      const targetList = this.lang === 'hk' ? this.list : this.list_en

      return targetList.filter(item => item.availibility)
    },
    cartListSet () {
      if (!this.cartList.length) return new Set([])
      else return new Set(this.cartList.map(item => item.id))
    }
  },
  mounted () {
    this.$nextTick(() => {
      const t = this.$refs.vs.classHooks.vBarVisible
      this.tipNeedShow = t
    })
  },
  methods: {
    ...mapMutations('cart', ['ADD_PRODUCT']),
    ...mapMutations('screensaver', ['RESET_TIMER']),
    ...mapActions('gtagEvents', ['addFavoriteListTrack']),
    handleScroll (vertical) {
      this.RESET_TIMER()
      this.tipNeedShow = vertical.process !== 1
    },
    handleResize (vertical) {
      const t = this.$refs.vs.classHooks.vBarVisible
      this.tipNeedShow = vertical.process !== 1 && !!t
    },
    onHeartClick (id) {
      if (!this.cartListSet.has(id)) {
        const el = this.$refs[`item${id}`][0]
        el.classList.add('product-list__item-add--active')
        el.addEventListener('animationend', () => {
          el.classList.remove('product-list__item-add--active')
        })
        const product = this.list.find(item => item.id === id)
        product.en_name = this.list_en.find(item => item.id === id).name
        product.count = 1
        this.ADD_PRODUCT(product)
        const type = this.$route.name === 'HighlightProduct' ? 'HL' : 'IP'
        this.addFavoriteListTrack({ type, code: product.code, lan: this.lang })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.product-list {
  height: 71.11111vh;
  margin-left: 1.5625vw;

  &__container {
    width: 38.698vw;
    padding-right: 2.7916667vw;
  }

  &__item {
    border-bottom: 1px solid $deep-gray;
    padding-bottom: 1.574vh;
    margin-bottom: 1.574vh;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-img {
    width: 11.4583vw;
    height: 11.4583vw;
    margin-right: 1.041667vw;
    @include bg-cover;
  }

  &__item-info-box {
    max-width: 16.6667vw;
  }

  &__item-brand {
    font-size: 1.875vw;
    line-height: 1;
    letter-spacing: -0.05625vw;
  }

  &__item-name {
    font-size: 1.3541667vw;
    line-height: 1.15;
    margin-top: 1.11111vh;
  }

  &__item-price {
    font-size: 2.8125vw;
    letter-spacing: -0.05625vw;
    margin-top: auto;
  }

  &__item-tip {
    font-size: 0.8333vw;
    line-height: 3.06;
  }

  &__item-interactive {
    margin-left: auto;
  }

  &__item-qrcode {
    width: 5.46875vw;
  }

  &__item-more-text {
    font-size: 0.9896vw;
    line-height: 1.05;
    letter-spacing: -0.0296875vw;
    text-align: center;
    margin-top: 0.74vh;

    &--en {
      font-size: 0.78125vw;
      line-height: 1.33;
      letter-spacing: -0.0234375vw;
    }
  }

  &__item-add {
    margin-top: auto;
    margin-left: auto;
    padding: 5px;
    background-color: #ffc222;
    border-radius: 0.74vh;

    &--active {
      animation: cartScale 1s ease-in-out;
      transform: scale(0.85);
      background-color: #d99e02;
    }
  }

  &__scroll-tip-block {
    position: absolute;
    padding-top: 1.574vh;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
}

@keyframes cartScale {
  0% {
    background-color: #ffc222;
    transform: scale(1);
  }

  50% {
    background-color: #d99e02;
    transform: scale(0.85);
  }

  100% {
    background-color: #ffc222;
    transform: scale(1);
  }
}
</style>
